
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function scopeCartItem1() {
                    var cartItem = window.cartItems.find(i => i.variantId == this.variant_ids[0]);
                    return _createElement('div', {
                        'className': 'quantity-selector-container',
                        'key': '1599'
                    }, _createElement('button', {
                        'type': 'button',
                        'data-variant-id': this.variant_ids[0],
                        'className': 'quantity-button decrease'
                    }, '-'), _createElement('span', {
                        'id': 'quantity_' + this.variant_ids[0],
                        'className': 'quantity-display ' + (cartItem ? 'active' : '')
                    }, '\n        ', cartItem ? cartItem.quantity : 0), _createElement('button', {
                        'type': 'button',
                        'data-variant-id': this.variant_ids[0],
                        'className': 'quantity-button increase'
                    }, '+'), _createElement('span', { 'className': 'cart-indicator ' + (cartItem ? 'active' : '') }, '\u2022'));
                }
                return _createElement('li', {
                    'className': 'product-list-item',
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'product-image-container' }, _createElement('a', { 'href': this.url }, _createElement('div', { 'className': 'product-image-wrapper' }, _createElement('img', {
                    'className': 'product-image',
                    'data-sizes': 'auto',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))), _createElement('div', { 'className': 'product-info' }, _createElement('h3', { 'className': 'product-title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-price' }, !this.on_sale ? _createElement('div', {
                    'className': 'price__regular',
                    'key': '575'
                }, this.price_varies ? _createElement('span', { 'key': '634' }, 'From: ') : null, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item price-item--regular price-item--set',
                    'key': '779'
                }, '\n          Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n        ') : null) : null, this.on_sale ? _createElement('div', {
                    'className': 'price__sale',
                    'key': '1004'
                }, _createElement('span', { 'className': 'price-item price-item--last price-item--sale' }, ' ', this.formatPrice(this.price), ' '), _createElement('span', { 'className': 'price-item price-item--regular' }, '\n           ', _createElement('del', {}, this.formatPrice(this.compare_at_price))), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'price-item price-item--last price-item--sale price-item--set',
                    'key': '1309'
                }, '\n          Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n        ') : null) : null)), _createElement('div', { 'className': 'product-actions' }, this.stock.includes('Available') ? scopeCartItem1.apply(this, []) : null, !this.stock.includes('Available') ? _createElement('button', {
                    'type': 'button',
                    'className': 'sold-out-button',
                    'disabled': true,
                    'key': '2288'
                }, '\n      Sold out\n    ') : null));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []