import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
  SearchRequestDefaults: {
    ...shopifyDawnGenericDefaults.SearchRequestDefaults,
    sort: window.Convermax.config?.defaultSort ?? 'sku',
  },
  Widgets: [
    ...shopifyDawnGenericDefaults.Widgets.filter(
      (w) => !['SearchHeader', 'SearchBoxDialogButton'].includes(w.name),
    ),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
        'sku': 'SKU: A to Z',
      },
    },
  ],
};
