//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-884:_748,_8112,_5628,_5364,_636,_6024,_2708,_4256;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-884')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-884', "_748,_8112,_5628,_5364,_636,_6024,_2708,_4256");
        }
      }catch (ex) {
        console.error(ex);
      }